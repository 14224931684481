body {
  scroll-behavior: smooth;
  overflow-x: hidden;

  &:has(.navigation-item > .submenu.open),
  &.nav-open {
    position: relative;
    overflow-y: hidden;

    &:before {
      inset: 0;
      background-color: rgba(0, 0, 0, 0.16);
      z-index: 999;
    }
  }

  &:before {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    z-index: -1;
    transition: all 0.5s;
  }
}
