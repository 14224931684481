.article-link,
.event-link {
  display: block;
  flex: 1;

  &:hover {
    text-decoration: none;
    cursor: pointer;

    .article,
    .event {
      .news-img,
      .event-img {
        img {
          transform: scale(1.07);
          -webkit-transition: transform 0.2s;
          -moz-transition: transform 0.2s;
          -ms-transition: transform 0.2s;
          -o-transition: transform 0.2s;
          transition: transform 0.2s;
        }
      }
    }
  }

  .mediaelement-video {
    background: $light-grey;
    position: relative;
    z-index: 2;
  }

  .article,
  .event {
    .news-img,
    .event-img {
      overflow: hidden;
      margin-bottom: 15px;

      img {
        transform: scale(1);
      }
    }

    .event-date {
      margin-bottom: 5px;
      font-weight: 600;
    }

    .header {
      margin-bottom: 15px;
      color: $dark-grey;
    }

    .teaser-text {
      color: #000000;
      margin-bottom: 0;

      p {
        margin-bottom: 0;
      }
    }

    .more-link-clickarea {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }

    .more-link {
      &::before {
        font-family: 'Font Awesome 5 Pro';
        content: '\f138';
        font-weight: 900;
        margin-right: 5px;
      }
    }
  }

  &:hover .more-link-text,
  &:focus .more-link-text {
    text-decoration: underline;
  }
}

.news-item,
.event-item {
  .news-img,
  .event-img {
    img {
      transform: scale(1);
      -webkit-transition: transform 0.2s;
      -moz-transition: transform 0.2s;
      -ms-transition: transform 0.2s;
      -o-transition: transform 0.2s;
      transition: transform 0.2s;
    }
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;

    .article,
    .event {
      .news-img,
      .event-img {
        img {
          transform: scale(1.07);
          -webkit-transition: transform 0.2s;
          -moz-transition: transform 0.2s;
          -ms-transition: transform 0.2s;
          -o-transition: transform 0.2s;
          transition: transform 0.2s;
        }
      }
    }
  }
}

.news-single,
.event-single {
  .article,
  .event {
    .header {
      h1 {
        font-size: 40px;
        margin-bottom: 30px;
      }
    }

    .news-text-wrap {
      margin-bottom: 0;
    }

    .teaser-text {
      color: #888;

      * {
        font-size: 26px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .detail-image {
      figure {
        padding: 0 0 30px 30px;
      }
    }

    .news-backlink-wrap {
      //margin-top: 60px;

      a {
        font-weight: 600;

        &::before {
          font-family: 'Font Awesome 5 Pro';
          content: '\f138';
          font-weight: 900;
          margin-right: 5px;
        }
      }
    }
  }
}

.news {
  .news-menu-view {
    text-align: left;
    margin-bottom: 40px;
    margin-top: 40px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-right: 24px;

        & + li {
          margin-right: 24px;
          margin-bottom: 16px;
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }

        &.itemactive {
          a {
            border-bottom: 2px solid $secondary;
          }
        }

        a {
          color: $darker-grey;
          padding-bottom: 5px;

          &:hover,
          &:focus {
            text-decoration: none;
            border-bottom: 2px solid $secondary;
          }
        }
      }
    }
  }
}

.more-link-container {
  display: flex;

  .facebook-share-button-list {
    width: 25px;
    height: 25px;
    margin-left: auto;
    background: #3b5998;
    border-radius: 12.5px;

    a {
      min-width: 25px;
      min-height: 25px;
      display: block;
      position: relative;
      z-index: 2;

      .facebook-text {
        opacity: 0;
      }
    }
  }

  .facebook-share-button-list:hover {
    width: 75px;
    transition: width 0.5s;

    a {
      min-width: 75px;
      text-decoration: none;

      .facebook-text {
        transition: opacity 1s;
        opacity: 1;
        font-weight: 700;
        font-size: 0.75em;
        color: white;
      }
    }
  }
}

.facebook-share-button-container {
  .facebook-share-button-detail {
    width: 160px;
    height: 35px;
    margin-left: auto;
    background: linear-gradient(to bottom, rgba(94, 128, 200, 1), rgba(57, 87, 169, 1));
    background: -webkit-linear-gradient(to bottom, rgba(94, 128, 200, 1), rgba(57, 87, 169, 1));
    background: -moz-linear-gradient(to bottom, rgba(94, 128, 200, 1), rgba(57, 87, 169, 1));
    background: -o-linear-gradient(to bottom, rgba(94, 128, 200, 1), rgba(57, 87, 169, 1));
    border-radius: 7.5px;

    a {
      min-width: 160px;
      min-height: 35px;
      display: block;

      .facebook-text {
        font-size: 0.75em;
        color: white;
      }

      img {
        margin: 5px;
        border-right: 1px solid #3b5998;
      }
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.image-teaser {
  &__item {
    position: relative;
    margin-bottom: $grid-gutter-width * 1.5;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__imagecontainer {
    position: relative;

    .img-fluid {
      max-width: unset;
      width: 100%;
    }
  }

  &__title {
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    padding: $grid-gutter-width * 0.25;
    width: 100%;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
  }

  &__more {
    &::before {
      font-family: 'Font Awesome 5 Pro';
      content: '\f138';
      font-weight: 900;
      margin-right: 5px;
    }
  }

  &__link {
    display: block;
    margin-top: $grid-gutter-width * 0.5;

    &:hover,
    &:focus {
      text-decoration: none;

      .image-teaser__link-text {
        text-decoration: underline;
      }
    }
  }

  &__clickarea {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
  }
}

.news__article {
  &-subheader {
    text-wrap: balance;
  }

  .frame {
    margin-bottom: $my-gutter * 2;
  }

  &-authors {
    & > li {
      flex: 1 0 calc(50% - #{$my-gutter});
      padding: $my-gutter * 0.5;
    }

    &--title {
      font-size: 22px;
      font-weight: 300;
    }

    .comma-separated-list li {
      font-weight: 600;
    }
  }
}
