.frame-type-theme_project,
.frame-type-theme_key_subject {
  margin-top: $my-gutter * 2;
  margin-bottom: $my-gutter * 2;
}

.card {
  &__heading {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__image {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    picture {
      grid-row: 1/1;
      grid-column: 1/1;

      & + picture {
        align-self: flex-end;
        justify-self: flex-start;
      }
    }
  }

  &__logo {
    background: #fff;

    img {
      aspect-ratio: 25/11;
      width: 150px;
      height: 66px;
      object-fit: contain;
    }
  }

  &__bodytext {
    p:last-of-type {
      margin: 0;
    }
  }
}
