$search__select--native-breakpoint: md;
$search__expandable--breakpoint: md;
$search__form-input--max-width: 270px;
$search__form-submit-width: 50px;

.search {
  &__result {
    position: relative;
    border: 1px solid $secondary;
    margin-bottom: $grid-gutter-width * 0.5;
    padding: $grid-gutter-width * 0.5;
  }

  &__badge {
    @extend .text-reset;

    display: flex;
    padding: 0 $grid-gutter-width * 0.5;
    height: $grid-gutter-width * 1.25;
    align-items: center;
    border: 1px solid $dark;
    margin-right: $grid-gutter-width * 0.5;

    &:hover {
      text-decoration: none;
    }

    &-icon {
      font-size: 1.5rem;
    }
  }

  &__form {
    height: $grid-gutter-width * 1.25;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    transition:
      background 0.2s ease,
      color 0.2s ease;
    position: relative;
    background: $superlight-grey;
    color: $dark;

    &-input {
      border: none;
      background: transparent;
      display: block;
      height: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      color: inherit;
      overflow: hidden;
      width: auto;
      padding: 0 $grid-gutter-width * 0.5;
      font-size: 16px;
    }

    &-submit {
      border: none;
      background: transparent;
      height: 100%;
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      color: $bodycolor;
      font-size: 1.3em;
      padding: 0 $grid-gutter-width * 0.5;
      width: $search__form-submit-width;
    }

    &--expandable {
      position: static;
      background: transparent;
      color: currentColor;

      @include media-breakpoint-up($search__expandable--breakpoint) {
        position: relative;
      }

      .search__suggestions {
        --suggestions-left: 0;

        left: var(--suggestions-left);

        @include media-breakpoint-up(md) {
          --suggestions-left: -273px;
        }
      }

      &:not(.search__form--expanded) .search__suggestions {
        display: none !important;
      }
    }

    &--expandable &-input {
      position: absolute !important;
      right: $search__form-submit-width;
      background: inherit;
      top: 0;
      transition:
        width 0.2s ease,
        padding 0.2s ease;
      width: 0;
      padding: 0;
    }

    &--expandable#{&}--expanded {
      background: $light;
      color: $dark;
    }

    &--expandable#{&}--expanded &-input {
      width: calc(100% - #{$search__form-submit-width});
      padding: 0 $grid-gutter-width * 0.5;

      @include media-breakpoint-up($search__expandable--breakpoint) {
        width: $search__form-input--max-width;
      }
    }
  }

  &__select {
    @include custom-select(transparent);

    position: relative;
    overflow: unset;

    &-label {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      user-select: none;
      cursor: pointer;
      padding: 0 $grid-gutter-width * 0.5;
      margin: 0;
      border: none;
      background: transparent;
      width: 100%;

      &--native {
        @include media-breakpoint-up($search__select--native-breakpoint) {
          display: none;
        }
      }

      &--custom {
        display: none;

        @include media-breakpoint-up($search__select--native-breakpoint) {
          display: flex;
        }
      }
    }

    &-input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      width: 100%;
      background: transparent;
      font-size: 0;
      appearance: none;
      border: none;

      option {
        font-size: 1rem;
      }

      @include media-breakpoint-up($search__select--native-breakpoint) {
        display: none;
      }
    }

    &-options {
      position: absolute;
      list-style: none;
      margin: 0;
      padding: 0;
      top: 100%;
      left: 0;
      right: 0;
      border-top-width: 0;
      background: $light;
      z-index: 9999;
      display: none;
      box-shadow: 0 4px 4px fade-out($dark, 0.75);

      &--active {
        display: block;
      }
    }

    &-option {
      display: flex;
      padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
      align-items: center;
      user-select: none;
      cursor: pointer;

      &:hover {
        background: fade-out($dark, 0.8);
      }
    }

    &-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $grid-gutter-width * 0.75;
      height: $grid-gutter-width * 0.75;
      border: 1px solid currentColor;
      margin-right: $grid-gutter-width * 0.5;

      &--active {
        @extend .fal, .fa-check;

        color: $light;
        background: $dark;
      }
    }
  }

  &__suggestions {
    position: absolute;
    display: block;
    top: 100%;
    right: 0;
    left: 0;

    .autocomplete-suggestions {
      width: 100% !important;
      background: #fff;
      box-shadow: 0px 16px 40px 0px rgba(0, 0, 0, 0.08);
      overflow: auto;
      padding: $my-gutter * 0.25 0;
    }

    .autocomplete-suggestion {
      padding: 12px $my-gutter * 0.5;
      cursor: pointer;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;

      strong {
        font-weight: 600;
      }

      &.autocomplete-selected,
      &:hover {
        background-color: var(--highlight-1-color);
      }
    }
  }
}

// Relaunch 2024 Styles
.search__bar {
  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .search-item & {
    &:has(.search__form--expanded) {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        width: var(--navigation-main-width);
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 1) 65%);
      }
    }
  }

  .search {
    &__form {
      height: auto;

      &:focus-within {
        //outline: 2px solid currentColor;
      }

      &-input {
        --search-input-right: 110px;

        right: var(--search-input-right);
        z-index: 1000;
        visibility: hidden;
        border-radius: 0;

        @include media-breakpoint-up(md) {
          --search-input-right: 54px;
        }

        @include media-breakpoint-up(xl) {
          --search-input-right: 46px;
        }
      }

      &--expanded {
        .search__form-input {
          --seach-input-width: calc(100dvw - 110px);

          width: var(--seach-input-width);
          border-top: 2px solid var(--navigation-link-hover-color);
          border-bottom: 2px solid var(--navigation-link-hover-color);
          border-left: 2px solid var(--navigation-link-hover-color);
          visibility: visible;

          &:focus-visible {
            border-top: 2px solid var(--navigation-link-hover-color);
            border-bottom: 2px solid var(--navigation-link-hover-color);
            border-left: 2px solid var(--navigation-link-hover-color);
            outline: none;
          }

          @include media-breakpoint-up(md) {
            --seach-input-width: 275px;
          }
        }

        .search__form-submit {
          outline: 2px solid var(--navigation-link-hover-color);
          outline-offset: -2px;
        }
      }

      &-submit {
        padding: $my-gutter * 0.5;
        font-size: 16px;
        width: auto;
        line-height: 1;

        @include media-breakpoint-up(xl) {
          padding: $my-gutter * 0.5 $my-gutter * 0.5 $my-gutter * 0.5 $my-gutter * 0.25;
        }

        &:focus-visible {
          outline: 2px solid var(--navigation-links-color);
          outline-offset: -2px;
          z-index: 1000;
        }
      }

      &--expandable &-input {
        &:focus-visible {
          outline: none;
        }
      }

      &--expandable#{&}--expanded &-input {
        padding: $my-gutter * 0.5 $my-gutter * 0.25 $my-gutter * 0.5 $my-gutter * 0.5;
      }
    }

    &__suggestions {
      position: fixed;
      top: var(--header-height);

      @include media-breakpoint-up(md) {
        position: absolute;
        top: 100%;
      }

      .autocomplete-suggestions {
        max-height: calc(100dvh - var(--header-height)) !important;
      }
    }
  }
}
