#footer {
  @include theme-color-change;

  background: var(--footer-background);
  padding: #{$my-gutter};

  a {
    color: $bodycolor;
    font-size: 16px;
    line-height: 1;

    @include link-underlines;

    &.footer__socialMedia-item__link {
      color: var(--sm-icon-color, var(--maincolor));
    }
  }
}
