.frame-type-experts {
  &_list {
    .experts {
      &__extended {
        aside {
          margin-bottom: $my-gutter * 0.25;

          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }

        &:nth-of-type(even) {
          aside {
            @include media-breakpoint-up(md) {
              order: 1;
            }
          }
        }
      }
    }
  }

  &_detail {
    .btn-text {
      color: var(--maincolor);
    }

    h1 {
      @include media-breakpoint-up(xl) {
        font-size: 48px;
      }
    }

    .experts {
      &__contact {
        &-grid {
          display: grid;
          grid-auto-rows: max-content auto;
          grid-template-columns: 1fr auto;
        }

        &-left {
          grid-row: 1/1;
          grid-column: 1/1;
        }

        &-right {
          grid-row: 1/1;
          grid-column: 2/2;
        }

        &-span {
          grid-row: 2/2;
          grid-column: 1 / span 2;
        }

        &-address {
          line-height: 1.5;
          font-size: 14px;
        }
      }

      &__address-heading {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &_detail + .frame-type-form_formframework {
    @extend .d-print-none;
  }
}
