header#header {
  @include theme-color-change;

  --navigation-link-color: #{$bodycolor};
  --navigation-link-hover-color: var(--navigation-links-color);
  --navigation-main-background-color: var(--highlight-1-color);
  --navigation-meta-background-color: var(--highlight-2-color);

  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;

  @include media-breakpoint-up(xl) {
    --navigation-main-background-color: #{$white};

    position: sticky;
    top: calc(0px - var(--meta-navigation-height));
  }

  .header-wrapper {
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    top: 0;
    max-height: 100dvh;

    .nav-open & {
      overflow-y: scroll;

      &:has(.submenu .submenu.open) {
        overflow-y: hidden;
      }

      overflow-x: hidden;
    }

    @include media-breakpoint-up(xl) {
      position: unset;
      background-color: $white;
    }

    // when searchform on mobile is toggled, hide the logo
    @include media-breakpoint-down(sm) {
      &:has(.search__form--expanded) {
        .navigation-logo {
          z-index: 0;

          a {
            transform: translateY(-100%);
          }
        }
      }
    }
  }
}
