@mixin link-underlines() {
  &:hover,
  &:focus,
  &:focus-visible {
    color: var(--underline-color);
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
  }
}
