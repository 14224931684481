.frame-type-HeaderWithIcon {
  &.frame-1337 {
    display: flex;
    justify-content: center;

    & > header {
      padding: 0 $my-gutter;
      min-width: 50%;
    }
  }
}
