h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

h1,
.h1 {
  font-size: $base-font-size;
  margin-bottom: 15px;
}

h2,
.h2 {
  @include header-size(2px);
  margin-bottom: 15px;
}

h3,
.h3 {
  @include header-size(4px);
  margin-bottom: 15px;
}

h4,
.h4 {
  @include header-size(6px);
  margin-bottom: 15px;
}

h5,
.h5 {
  @include header-size(8px);
  margin-bottom: 15px;
}

h6,
.h6 {
  @include header-size(10px);
  margin-bottom: 15px;
}
p,
li {
  font-size: 14px;
}
a {
  &:hover {
    color: $primary;
  }
}
