#cookie-bar {
  position: fixed;
  width: 100%;
  background: $primary;
  height: auto;
  min-height: 24px;
  line-height: 24px;
  color: #eeeeee;
  text-align: center;
  display: none;
  z-index: 4000;
  opacity: 1;
  padding: 15px 0;
  box-shadow: #000 -2px 0 10px -2px;
  bottom: 0;
  top: auto;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    &.bottom {
      bottom: 0;
      top: auto;
    }
  }
  p {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 14px;
  }
  a {
    color: #000;
    display: inline-block;
    text-decoration: none;
    padding: 0 6px;
    margin-left: 8px;
  }
  .cb-enable,
  .terms {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px 30px;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    color: #fff !important;
    font-family: 'Open Sans', sans-serif;
    background-color: transparent !important;
    border: 2px solid #fff;
    border-radius: 10px 10px 10px 10px;
    &:hover {
      color: $primary !important;
      background: #fff !important;
    }
  }
  .cb-disable {
    background: #ffffff;
  }
  .cb-disable:hover {
    background: #eeeeee;
  }
  .cb-policy {
    background: #ffffff;
  }
  .cb-policy:hover {
    background: #eeeeee;
  }
}

@media (max-width: 991px) {
  #cookie-bar {
    a {
      margin-left: 0;
    }
  }
}
