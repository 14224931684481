.form {
  max-width: 730px;

  .form-group {
    margin-bottom: $grid-gutter-width;
  }

  .checkbox {
    margin-left: 20px;
    margin-bottom: $grid-gutter-width * 0.25;
  }

  label {
    font-size: 14px;
    margin-bottom: 0.1875rem;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .form-control {
    border: 1px solid $border-color;
    border-radius: 0;
    height: 52px;

    &:focus {
      border-color: $dark;
      box-shadow: 0 0 0 1px $dark;
    }
  }

  select.form-control {
    @include custom-select($light);

    option {
      &:checked {
        background-color: $primary;
      }

      &:hover {
        box-shadow: 0 0 10px 100px $primary inset;
      }
    }
  }

  textarea.form-control {
    min-height: 104px;
  }

  .form-check {
    .form-check-input {
      appearance: none;
      border: 1px solid $border-color;
      border-radius: 0;
      height: $grid-gutter-width * 0.5;
      width: $grid-gutter-width * 0.5;
      margin-top: 0.2rem;

      &:focus {
        border-color: $dark;
        box-shadow: 0 0 0 1px $dark;
      }

      &:checked {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden='true' focusable='false'><path fill='none' stroke='white' stroke-width='3' d='M1.73 12.91l6.37 6.37L22.79 4.59'/></svg>");
        background-color: $dark;
        background-size: calc(#{$grid-gutter-width * 0.5} - 4px);
        background-position: center center;
        background-repeat: no-repeat;
      }
    }

    .invalid-feedback,
    .valid-feedback {
      margin-left: -$grid-gutter-width * 0.5 - 4px;
    }
  }

  .btn {
    border-radius: 0;
    display: inline-block;
    padding: 12px 18px;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;

    &:focus {
      box-shadow: none;
    }

    &-cancel {
      padding: 12px 0;
    }
  }

  .form-control.is-valid,
  .form-control.is-invalid,
  &.was-validated .form-control:valid,
  &.was-validated .form-control:invalid {
    background: transparent;
    padding-right: inherit;
  }

  .form-control.is-valid,
  &.was-validated .form-control:valid {
    border-color: $valid;
    color: $valid;

    &:focus {
      box-shadow: 0 0 0 1px $valid;
    }
  }

  .form-control.is-invalid,
  &.was-validated .form-control:invalid {
    border-color: $invalid;
    color: $invalid;

    &:focus {
      box-shadow: 0 0 0 1px $invalid;
    }
  }

  fieldset {
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;

    legend {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: $grid-gutter-width !important;
      display: flex;
      flex-direction: column;

      small {
        margin-top: 8px;
        font-size: 14px;
        line-height: 1.2;
      }
    }
  }

  .form-group--only-last-error {
    .form-group:not(:last-of-type) .invalid-feedback {
      display: none !important;
    }

    .form-group:last-of-type .invalid-feedback {
      background: $invalid;
      color: $light;
      padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
      margin-top: $grid-gutter-width * 0.5;
      justify-content: flex-start;
      align-items: center;

      svg {
        margin-right: $grid-gutter-width * 0.25;
      }
    }
  }

  &.was-validated .form-group--only-last-error .form-group:last-of-type :invalid ~ .invalid-feedback {
    display: flex;
  }
}

form.questionnaire {
  table td {
    border-top: 1px solid $superlight-grey;
    padding: 20px 0;
  }

  .radio-group {
    margin-left: 20px;
    margin-top: $grid-gutter-width * 0.25;

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: row;
    }

    .radio {
      @include media-breakpoint-up(sm) {
        flex-grow: 0.1;
      }
    }
  }
}
