.pagination {
  transform: scale(0.8);

  @include media-breakpoint-up(sm) {
    transform: none;
  }

  .page-link {
    font-size: 1rem;
    background: var(--maincolor);

    @include media-breakpoint-up(md) {
      padding-left: $pagination-padding-x--md;
      padding-right: $pagination-padding-x--md;
    }
  }

  .page-item:first-child,
  .page-item:last-child {
    .page-link {
      font-size: 0;

      &::before {
        font-size: 1rem;
      }
    }
  }

  .page-item:first-child .page-link::before {
    content: '<';
  }

  .page-item:last-child .page-link::before {
    content: '>';
  }

  &__simple {
    .page-item:first-child,
    .page-item:last-child {
      .page-link {
        font-size: 1rem;

        &:before {
          content: '';
        }
      }
    }
  }
}
