.sidebar {
  list-style: none;
  margin: 0;
  padding: 0;
  border-left: 3px solid $secondary;
  li {
    a {
      color: $dark-grey;
      padding: 2.5px 15px;
      &.active {
        color: $primary;
        font-weight: 600;
      }
    }
    ul {
      li {
        a {
          padding-left: 0;
        }
        ul {
          padding-left: 20px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .sidebar {
    margin-bottom: 30px;
  }
}
