main {
  padding: 45px 0;
  header {
    &.yellow-header {
      border-bottom: 2px solid $primary;
      * {
        color: $primary;
        font-weight: 300;
      }
      i {
        font-size: 1.3em;
        margin-right: 15px;
      }
    }
  }
}
.ce-gallery {
  figcaption {
    font-size: 14px;
    padding: 9px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    color: #777;
  }
}
.ce-border {
  img {
    border: 1px solid #ddd;
  }
}
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
  font-style: italic;
}
.highlight {
  color: $primary;
  font-weight: 700;
}

.frame-type-Accordion .card {
  border: 0;
}

.frame-type-Accordion .card .card-header {
  background-color: #fff;
  border-bottom: 0px;
}

.frame-type-Accordion .card .card-header button.btn-link {
  width: 100%;
  white-space: normal;
  color: $primary;
}

.frame-type-Accordion .card .card-header button.btn-link:hover {
  color: $primary;
}

@media (max-width: 991px) {
  .ce-bodytext {
    width: 100%;
  }
  .ce-intext .ce-gallery {
    margin: 0 auto 10px auto !important;
    float: unset !important;
    width: fit-content;
  }
}

@media (min-width: 992px) {
  .ce-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 30px;
    .ce-column {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: 0;
    }
  }
  .ce-gallery[data-ce-columns='3'] {
    .ce-column {
      width: 33.3333%;
    }
  }
  .ce-gallery[data-ce-columns='4'] {
    .ce-column {
      width: 25%;
    }
  }
  .ce-gallery[data-ce-columns='5'] {
    .ce-column {
      width: 20%;
    }
  }
  .ce-gallery[data-ce-columns='6'] {
    .ce-column {
      width: 16.6666666667%;
    }
  }
}

/*
.btn-link{
  &.collapsed {
    h2, h3 {
      color: #86BC24 !important;
    }
  }
  &[aria-expanded="true"] h2, &[aria-expanded="true"] h3 {
    color: #86BC24 !important;
  }
}
*/

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .top-bar {
    display: none;
  }
  header {
    display: none;
  }
  footer {
    display: none;
  }
  .news-backlink-wrap {
    display: none;
  }
  .sidebar {
    display: none;
  }
  .facebook-share-button-container {
    .facebook-share-button-detail {
      display: none;
    }
  }
  div#cookie-bar {
    display: none !important;
  }
}
