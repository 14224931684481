.frame-layout-article-overview {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      &::before {
        content: '»';
        display: inline-block;
        margin-right: 3px;
        color: $primary;
      }

      > a {
        color: #494948;
        font-weight: 700;
      }
    }
  }

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.frame-122 {
  position: relative;

  header {
    width: 100%;
    padding: 18px 12px;
    background: rgba(255, 255, 255, 0.76);
    position: absolute;
    bottom: 0;
    z-index: 10;

    h2,
    h3 {
      margin: 0;
    }

    h3 {
      font-size: 18px;
    }
  }

  .ce-gallery {
    margin: 0;
  }
}

.frame-layout-magazine-overview {
  .col-lg-4 {
    &:first-of-type {
      .magazine-image {
        position: relative;
        &:before {
          display: inline-block;
          content: 'NEU!';
          background: $primary;
          color: #fff;
          font-weight: 700;
          font-size: 18px;
          position: absolute;
          top: 0;
          right: 0;
          padding: 0 12px;
        }
      }
    }
    a {
      display: block;
      color: #000000;
      padding: 15px;

      &:hover {
        text-decoration: none;
        background: #eee;
      }

      .magazine-teaser {
        .magazine-title {
          font-size: 22px;
        }

        .more-link {
          color: $primary;
          font-size: 14px;

          &::before {
            font-family: 'Font Awesome 5 Pro';
            content: '\f138';
            font-weight: 900;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .frame-layout-magazine-overview {
    .col-lg-4 {
      border-right: 1px solid #eee;

      &:last-child {
        border-right: none;
      }
    }
  }
}
