.navbar-nav {
  li.dropdown.arrow-toggle {
    &:hover {
      & > .dropdown-menu,
      & > div > .dropdown-menu {
        display: block;
      }
      & > div .dropdown-menu {
        flex: 0 0 100%;
      }
    }

    a.nav-link {
      display: inline-block;
      font-size: 16px;
    }

    & > a[data-toggle='dropdown'],
    & > div > a[data-toggle='dropdown'] {
      padding-right: 5px;

      &:before {
        width: 15px;
        height: 17px;
        font-family: 'Font Awesome 5 Pro';
        content: '\f078';
        color: $dark-grey;
        vertical-align: middle;
        position: relative;
        bottom: -4px;
      }
    }
    &.show,
    &:hover {
      & > a[data-toggle='dropdown'],
      & > div > a[data-toggle='dropdown'] {
        &:before {
          content: '\f077';
        }
      }
    }
    &:last-of-type {
      & > a[data-toggle='dropdown'],
      & > div > a[data-toggle='dropdown'] {
        padding-right: 0;
      }
    }
  }
}
img {
  max-width: 100%;
  height: auto;
}
