.arrow {
  position: relative;
  width: var(--arrow-width);
  height: 24px;
  display: block;
  transition: width 0.25s linear;

  &-line {
    position: absolute;
    top: 11.4px;
    background-color: currentColor;
    height: var(--arrow-line-height);
    width: 100%;
    border-radius: 1px;
  }

  &:before {
    content: '';
    background-color: currentColor;
    position: absolute;
    top: 9px;
    right: -3px;
    height: var(--arrow-line-height);
    width: 8px;
    transform: rotate(45deg);
    border-radius: 1px;
  }

  &:after {
    content: '';
    background-color: currentColor;
    position: absolute;
    top: 13px;
    right: -3px;
    height: var(--arrow-line-height);
    width: 8px;
    transform: rotate(-45deg);
    border-radius: 1px;
  }
}
