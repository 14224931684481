:root {
  --container-max-width: 2560px;

  --header-height: 56px;
  --main-navigation-logo-width: 137px;
  --meta-navigation-height: 56px;

  // main colors (new)
  --maincolor-eli: #{$dark-blue};
  --maincolor-eli-hover: #{$dark-blue-hover};
  --highlight-1-eli: #{#ecf3f8};
  --highlight-2-eli: #{#f4fafe};

  --maincolor-wohnen: #{$turquoise-500};
  --maincolor-wohnen-hover: #{$turquoise-900};
  --highlight-1-wohnen: #{#f0fbfc};
  --highlight-2-wohnen: #{#f6fdfe};

  --maincolor-forte: #{$yellow-500};
  --maincolor-forte-hover: #{$yellow-900};
  --highlight-1-forte: #{#fff9e7};
  --highlight-2-forte: #{#fffdf8};

  --maincolor-elisana: #{$green-500};
  --maincolor-elisana-hover: #{$green-900};
  --highlight-1-elisana: #{#fff9e7};
  --highlight-2-elisana: #{#fffdf8};

  --maincolor-medical: #{$yellow-100};
  --maincolor-medical-hover: #{$yellow-400};
  --highlight-1-medical: #{#fff9e7};
  --highlight-2-medical: #{#fffdf8};

  --maincolor-health: #{$blue-300};
  --maincolor-health-hover: #{$blue-400};
  --highlight-1-health: #{#eefaff};
  --highlight-2-health: #{#f9fdff};

  --maincolor-eli-pflege: #{$orange-500};
  --maincolor-eli-pflege-hover: #{$orange-900};
  --highlight-1-eli-pflege: #{#fff9e7};
  --highlight-2-eli-pflege: #{#fffdf8};

  --maincolor-gesundheitsnetzwerk: #{$blue-500};
  --maincolor-gesundheitsnetzwerk-hover: #{$blue-600};
  --highlight-1-gesundheitsnetzwerk: #{#fff9e7};
  --highlight-2-gesundheitsnetzwerk: #{#fffdf8};

  --maincolor-eliat-hover: #{$red-900};
  --highlight-1-eliat: #{#fff7f7};
  --highlight-2-eliat: #{#fffdfd};

  --maincolor-cardiomed: #{$red-500};
  --maincolor-cardiomed-hover: #{$red-400};
  --highlight-1-cardiomed: #{$lightblue-100};
  --highlight-2-cardiomed: #{#fafbff};

  --footer-background: #{$extremlightgrey};

  @include media-breakpoint-up(md) {
    --main-navigation-logo-width: 157px;
  }

  @include media-breakpoint-up(xl) {
    --header-height: 72px;
    --main-navigation-logo-width: 192px;
  }

  @include media-breakpoint-up(uw) {
    --header-height: 88px;
    --main-navigation-logo-width: 232px;
  }
}
