.quicklinks {
  @include theme-color-change;

  --quicklinks-size: 16px;
  --heading-color: #{$bodycolor};
  --quicklinks-color: var(--maincolor);
  --svg-width: 20px;

  background-color: rgba(var(--maincolor-rgb2, var(--maincolor-rgb)), 0.1);

  @include media-breakpoint-up(uw) {
    --quicklinks-size: 18px;
    --svg-width: 24px;
  }

  &__item {
    display: flex;
    align-items: center;

    &-link {
      font-weight: 600;
      line-height: 1.5;
    }
  }

  h2 {
    font-weight: 600;
    font-size: var(--quicklinks-size);
    color: var(--heading-color);
    line-height: 1.5;

    button {
      border: none;
      background: none;
      color: var(--quicklinks-color);

      @include motion-safe {
        transition: transform 0.2s ease-in-out;
      }

      &[aria-expanded='true'] {
        transform: rotate(180deg);
      }
    }
  }

  &__item-link {
    --underline-color: var(--quicklinks-color);

    font-size: var(--quicklinks-size);
    color: var(--quicklinks-color);

    svg {
      width: var(--svg-width);
      aspect-ratio: 1/1;
    }

    @include link-underlines;
  }
}
