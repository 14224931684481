/* open-sans-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../Fonts/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
  src:
    local('Open Sans Light'),
    local('OpenSans-Light'),
    url('../Fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/open-sans-v15-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/open-sans-v15-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/open-sans-v15-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Open Sans Regular'),
    local('OpenSans-Regular'),
    url('../Fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/open-sans-v15-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/open-sans-v15-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/open-sans-v15-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../Fonts/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
  src:
    local('Open Sans SemiBold'),
    local('OpenSans-SemiBold'),
    url('../Fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/open-sans-v15-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/open-sans-v15-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/open-sans-v15-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../Fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url('../Fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/open-sans-v15-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/open-sans-v15-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/open-sans-v15-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* raleway-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url('../Fonts/raleway-v12-latin-300.eot'); /* IE9 Compat Modes */
  src:
    local('Raleway Light'),
    local('Raleway-Light'),
    url('../Fonts/raleway-v12-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/raleway-v12-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/raleway-v12-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/raleway-v12-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/raleway-v12-latin-300.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/raleway-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Raleway'),
    local('Raleway-Regular'),
    url('../Fonts/raleway-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/raleway-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/raleway-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/raleway-v12-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/raleway-v12-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('../Fonts/raleway-v12-latin-600.eot'); /* IE9 Compat Modes */
  src:
    local('Raleway SemiBold'),
    local('Raleway-SemiBold'),
    url('../Fonts/raleway-v12-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/raleway-v12-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/raleway-v12-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/raleway-v12-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/raleway-v12-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('../Fonts/raleway-v12-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local('Raleway Bold'),
    local('Raleway-Bold'),
    url('../Fonts/raleway-v12-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/raleway-v12-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/raleway-v12-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/raleway-v12-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/raleway-v12-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../Fonts/merriweather-sans-v9-latin-300.eot'); /* IE9 Compat Modes */
  src:
    local('Merriweather Sans Light'),
    local('MerriweatherSans-Light'),
    url('../Fonts/merriweather-sans-v9-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/merriweather-sans-v9-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/merriweather-sans-v9-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/merriweather-sans-v9-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/merriweather-sans-v9-latin-300.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/merriweather-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Merriweather Sans'),
    local('MerriweatherSans-Regular'),
    url('../Fonts/merriweather-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/merriweather-sans-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/merriweather-sans-v9-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/merriweather-sans-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/merriweather-sans-v9-latin-regular.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* merriweather-sans-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Merriweather Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../Fonts/merriweather-sans-v9-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local('Merriweather Sans Bold'),
    local('MerriweatherSans-Bold'),
    url('../Fonts/merriweather-sans-v9-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/merriweather-sans-v9-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/merriweather-sans-v9-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/merriweather-sans-v9-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/merriweather-sans-v9-latin-700.svg#MerriweatherSans') format('svg'); /* Legacy iOS */
}
/* lato-300 - latin */
@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../Fonts/lato-v14-latin-300.eot'); /* IE9 Compat Modes */
  src:
    local('Lato Light'),
    local('Lato-Light'),
    url('../Fonts/lato-v14-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/lato-v14-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/lato-v14-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/lato-v14-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/lato-v14-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/lato-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Lato Regular'),
    local('Lato-Regular'),
    url('../Fonts/lato-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/lato-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/lato-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/lato-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/lato-v14-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../Fonts/lato-v14-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local('Lato Bold'),
    local('Lato-Bold'),
    url('../Fonts/lato-v14-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/lato-v14-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/lato-v14-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/lato-v14-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/lato-v14-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
/* playfair-display-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/playfair-display-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Playfair Display Regular'),
    local('PlayfairDisplay-Regular'),
    url('../Fonts/playfair-display-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/playfair-display-v13-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/playfair-display-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/playfair-display-v13-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/playfair-display-v13-latin-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  src: url('/typo3conf/ext/siwa_project/Resources/Public/Fonts/playfair-display-v13-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local('Playfair Display Bold'),
    local('PlayfairDisplay-Bold'),
    url('/typo3conf/ext/siwa_project/Resources/Public/Fonts/playfair-display-v13-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/typo3conf/ext/siwa_project/Resources/Public/Fonts/playfair-display-v13-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/typo3conf/ext/siwa_project/Resources/Public/Fonts/playfair-display-v13-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('/typo3conf/ext/siwa_project/Resources/Public/Fonts/playfair-display-v13-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/typo3conf/ext/siwa_project/Resources/Public/Fonts/playfair-display-v13-latin-700.svg#PlayfairDisplay')
      format('svg'); /* Legacy iOS */
}
