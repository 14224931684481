@charset "utf-8";

@import '../../node_modules/frontend-pipeline-main/Src/Scss/Sassdash/sassdash';

// some useful functions
@import "/builds/customers/die-elisabethinen/die-elisabethinen-website/app/packages/theme/Build/node_modules/frontend-pipeline-main/Src/Scss/Functions/_implode.scss";
@import "/builds/customers/die-elisabethinen/die-elisabethinen-website/app/packages/theme/Build/node_modules/frontend-pipeline-main/Src/Scss/Functions/_rem.scss";
@import "/builds/customers/die-elisabethinen/die-elisabethinen-website/app/packages/theme/Build/node_modules/frontend-pipeline-main/Src/Scss/Functions/_strip-unit.scss";

// Custom variables
@import "Abstracts/_aspect-ratios.scss";
@import "Abstracts/_breakpoints.scss";
@import "Abstracts/_container.scss";
@import "Abstracts/_custom-variables.scss";

// mixins
@import "/builds/customers/die-elisabethinen/die-elisabethinen-website/app/packages/theme/Build/node_modules/frontend-pipeline-main/Src/Scss/Mixins/_font-settings.scss";
@import "/builds/customers/die-elisabethinen/die-elisabethinen-website/app/packages/theme/Build/node_modules/frontend-pipeline-main/Src/Scss/Mixins/_ie11.scss";
@import "/builds/customers/die-elisabethinen/die-elisabethinen-website/app/packages/theme/Build/node_modules/frontend-pipeline-main/Src/Scss/Mixins/_px-to-rem.scss";
@import "Mixins/_custom-select.scss";

// Vendor styles & libraries
@import 'Vendor/10-bootstrap';
@import 'Vendor/20-owl-carousel';
@import 'Vendor/30-supi';
@import 'Vendor/photoswipe/main';
@import 'Vendor/photoswipe/default-skin/default-skin';
@import 'Vendor/fontawesome/fontawesome';
@import 'Vendor/fontawesome/fa-light';
@import 'Vendor/fontawesome/fa-regular';
@import 'Vendor/fontawesome/fa-solid';
@import '../../node_modules/fullcalendar/dist/fullcalendar';
@import '../../node_modules/@splidejs/splide/dist/css/splide.min';

// utilities
//@import '../../node_modules/frontend-pipeline-main/Src/Scss/Utilities/**/*.scss';
@import '../../node_modules/frontend-pipeline-main/Src/Scss/Utilities/debug';
@import "Utilities/_colors.scss";
@import "Utilities/_link-underlines.scss";
@import "Utilities/_utilifies.scss";
@import "Utilities/motion-safe.scss";

// Import base styles
@import "Base/_body.scss";
@import "Base/_core.scss";
@import "Base/_footer.scss";
@import "Base/_frame.scss";
@import "Base/_html.scss";
@import "Base/_main.scss";
@import "Base/_pagination.scss";
@import "Base/_print.scss";
@import "Base/_responsive.scss";
@import "Base/_root.scss";

// Import styles for design elements
@import "Design/_calendar.scss";
@import "Design/_card.scss";
@import "Design/_carousel.scss";
@import "Design/_categories.scss";
@import "Design/_contactperson.scss";
@import "Design/_content.scss";
@import "Design/_cookiebar-override.scss";
@import "Design/_design.scss";
@import "Design/_experts.scss";
@import "Design/_figcaption.scss";
@import "Design/_fonts.scss";
@import "Design/_footer.scss";
@import "Design/_form.scss";
@import "Design/_header.scss";
@import "Design/_heading.scss";
@import "Design/_hero.scss";
@import "Design/_image-slider.scss";
@import "Design/_magazine.scss";
@import "Design/_mixins.scss";
@import "Design/_navbar.scss";
@import "Design/_news.scss";
@import "Design/_page.scss";
@import "Design/_podigee.scss";
@import "Design/_quicklinks.scss";
@import "Design/_search.scss";
@import "Design/_sidebar.scss";
@import "Design/_teaser.scss";
@import "Design/_topbar.scss";
@import "Design/_typography.scss";

// Import atom styles
@import "Atoms/_arrow.scss";
@import "Atoms/_hamburger.scss";
@import "Atoms/_skip-nav.scss";

*:focus-visible {
  border: none;
  outline-offset: -1px;
  outline-color: rgba(0, 0, 0, 0.5);
  outline-style: dashed;
}
