.categories {
  &__nav {
    a {
      color: var(--supi-color-primary);
      text-underline-offset: $my-gutter * 0.25;

      &.active {
        text-decoration: underline;
      }
    }
  }
}
