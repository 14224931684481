.top-bar {
  background-color: $light-grey;
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  min-height: 51px;

  .top-bar-info {
    a {
      color: #ffffff;
    }
  }

  .top-bar-menu {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;

        &:first-of-type {
          a {
            padding-left: 0;
          }
        }

        &:last-of-type {
          a {
            padding-right: 0;
          }
        }

        a {
          color: #ffffff;
          display: inline-block;
          padding: $grid-gutter-width * 0.5 ($grid-gutter-width);

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .top-bar-site-chooser {
    border: 1px solid #ccc;
    background: #fafafa no-repeat 90% 50%;
    overflow: hidden;
    position: relative;

    &::before {
      font-family: 'Font Awesome 5 Pro';
      content: '\f078';
      font-weight: 900;
      color: $dark-grey;
      font-size: 12px;
      vertical-align: middle;
      top: 50%;
      left: 16px;
      margin-top: -9px;
      position: absolute;
    }

    select {
      color: $dark-grey;
      font-weight: 600;
      border: none;
      box-shadow: none;
      background: none;
      appearance: none;
      overflow: hidden;
      width: 100%;
      padding: 8px 16px 8px 32px;
    }

    select:focus {
      outline: none;
    }
  }
}
