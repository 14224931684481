@media (min-width: 992px) {
  header#header {
    nav.navbar {
      ul.navbar-nav {
        .navbarDropdown {
          display: none;
        }
      }
    }
  }
}
