@use 'sass:math';

.hero {
  @include theme-color-change;

  --btn-color: #{$light};
  --btn-color-hover: #{$light};
  --btn-bg-color: var(--maincolor);
  --btn-bg-color-hover: var(--maincolor-hover);

  --hero-grid-rows: 2;
  --hero-grid-row-text: 2;

  --hero-spacing: #{$my-gutter};
  --hero-heading-bg-color: #{$light};
  --hero-heading-text-color: #{$heading-color};
  --hero-heading-font-size: 22px;
  --hero-heading-max-columns: 12;
  --hero-heading-line-height: 1.5;
  --hero-heading-spacing-inline: 0;
  --hero-heading-spacing-border-top: 0;
  --hero-heading-spacing-border-bottom: 0;

  --btn-font-size: 16px;
  --btn-padding-inline: var(--hero-spacing);
  --btn-padding-block: calc(var(--hero-spacing) * 0.5);

  --hero-gradient: var(--maincolor-rgb);

  @include media-breakpoint-up(md) {
    --hero-grid-rows: 1;
    --hero-grid-row-text: 1;

    --hero-heading-font-size: 22px;
    --hero-heading-max-columns: 6;
    --hero-heading-spacing-inline: calc(var(--hero-spacing) * 0.5);
    --hero-heading-spacing-border-top: calc(var(--hero-spacing) * 0.125);
    --hero-heading-spacing-border-bottom: calc(var(--hero-spacing) * 0.25);
  }

  @include media-breakpoint-up(xl) {
    --hero-heading-font-size: 30px;
    --hero-heading-max-columns: 5;
  }

  @include media-breakpoint-up(uw) {
    --hero-heading-font-size: 40px;
    --hero-heading-spacing-inline: calc(var(--hero-spacing) * 0.75);
  }

  display: grid;
  grid-template-rows: repeat(var(--hero-grid-rows), auto);
  grid-template-columns: 1fr;

  &__image,
  &__text {
    grid-column: 1;
  }

  &__image {
    position: relative;
    grid-row: 1;

    &:after {
      position: absolute;
      content: '';
      inset: 0;
      background: linear-gradient(103deg, rgba(var(--hero-gradient), 0.2) 0%, rgba(255, 255, 255, 0) 40%);
    }
  }

  &__text {
    z-index: 1;
    grid-row: var(--hero-grid-row-text);

    h1,
    .h1 {
      display: block;
      color: var(--hero-heading-text-color);
      font-size: var(--hero-heading-font-size);
      max-width: calc(100% / 12 * var(--hero-heading-max-columns));
      line-height: var(--hero-heading-line-height);
      padding-block: var(--hero-heading-spacing-border-top) var(--hero-heading-spacing-border-bottom);
      margin-bottom: 0;

      &:has(+ .btn) {
        margin-bottom: $my-gutter * 0.5;
      }

      font-weight: 600;
      text-wrap: balance;

      span {
        box-decoration-break: clone;
        background: var(--hero-heading-bg-color);
        padding-inline: var(--hero-heading-spacing-inline);
        border-top: var(--hero-heading-spacing-border-top) solid var(--hero-heading-bg-color);
        border-bottom: var(--hero-heading-spacing-border-bottom) solid var(--hero-heading-bg-color);
      }
    }
  }

  .btn {
    font-size: var(--btn-font-size);
    font-weight: 600;
    color: var(--btn-color);
    background: var(--btn-bg-color);
    border-color: var(--btn-bg-color);
    padding-inline: var(--btn-padding-inline);
    padding-block: var(--btn-padding-block);

    &:hover {
      color: var(--btn-color-hover);
      background: var(--btn-bg-color-hover);
      border-color: var(--btn-bg-color-hover);
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      color: var(--btn-color);
      background: var(--btn-bg-color);
      border-color: var(--btn-bg-color);
      box-shadow: none;
      outline: 2px solid var(--btn-bg-color);
      outline-offset: 2px;
    }
  }
}
