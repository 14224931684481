.carousel {
  position: relative;
  .carousel-inner {
    z-index: 0;
    .carousel-item {
      max-height: 500px;
      margin-bottom: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 300px;
      .carousel-item-inner {
        padding-bottom: 45px;
        position: relative;
        z-index: 1;
        .carousel-title {
          margin: 0;
          padding: 0;
          text-transform: lowercase;
          font-size: 50px;
          font-weight: 300;
          color: $dark-grey;
          &::before {
            font-family: 'Font Awesome 5 Pro';
            content: '\f324';
            font-weight: 400;
            color: $dark-grey;
            vertical-align: middle;
            font-size: 25px;
            margin-right: 15px;
          }
        }
      }
      .carousel-item-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 25%,
          rgba(255, 255, 255, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 25%,
          rgba(255, 255, 255, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        opacity: 0.6;
        z-index: 0;
      }
    }
  }
}
@media (min-width: 992px) {
  .carousel {
    .carousel-inner {
      .carousel-item {
        height: 600px;
      }
    }
  }
}
@media (min-width: 768px) {
  .carousel {
    .carousel-inner {
      .carousel-item {
        height: 450px;
      }
    }
  }
}
@media (max-width: 991px) {
  .carousel {
    .carousel-inner {
      .carousel-item {
        .carousel-item-inner {
          .carousel-title {
            font-size: 30px;
          }
        }
      }
    }
  }
}
