.navigation {
  &-main {
    --navigation-main-padding: calc((var(--main-navigation-logo-height) - var(--header-height)) + #{$my-gutter}) #{$my-gutter *
      0.75} #{$my-gutter};
    --navigation-main-margin: var(--header-height) 0 0 0;
    --navigation-main-transform: translateX(100%);
    --navigation-main-visibility: hidden;
    --navigation-main-grid-row: 2;
    --navigation-main-grid-column: 1 / 3;

    padding: var(--navigation-main-padding);
    transform: var(--navigation-main-transform);
    visibility: var(--navigation-main-visibility);
    grid-row: var(--navigation-main-grid-row);
    grid-column: var(--navigation-main-grid-column);
    margin: var(--navigation-main-margin);

    background-color: var(--navigation-main-background-color);
    transition: transform 0.25s linear;
    display: none;

    @include media-breakpoint-up(md) {
      --navigation-main-padding: #{$my-gutter} #{$my-gutter * 0.75};
      --navigation-main-grid-column: 2 / 3;
    }

    @include media-breakpoint-up(xl) {
      --navigation-main-padding: 0 #{$my-gutter} 0 0;
      --navigation-main-grid-row: 2;
      --navigation-main-grid-column: 2;
      --navigation-main-transform: unset;
      --navigation-main-visibility: visible;
      --navigation-main-margin: 0 0 0 #{$my-gutter};

      display: block;
    }

    @include media-breakpoint-up(uw) {
      --navigation-main-padding: 0;
    }

    .nav-open & {
      --navigation-main-transform: translateX(0);
      --navigation-main-visibility: visible;
      display: block;
    }
  }

  &-logo {
    --navigation-logo-padding-left: #{$my-gutter * 0.5};
    --navigation-logo-position: fixed;
    --navigation-logo-width: 50%;
    --navigation-grid-row: 1;

    max-height: var(--header-height);
    grid-row: var(--navigation-grid-row);
    grid-column: 1;
    background-color: $white;
    padding-left: var(--navigation-logo-padding-left);
    z-index: 1000;
    position: var(--navigation-logo-position);
    width: var(--navigation-logo-width);

    @include media-breakpoint-up(md) {
      --navigation-logo-padding-left: #{$my-gutter * 0.75};
    }

    @include media-breakpoint-up(xl) {
      --navigation-logo-padding-left: #{$my-gutter};
      --navigation-logo-position: unset;
      --navigation-logo-width: unset;
      --navigation-grid-row: 2;
    }

    a {
      --navigation-logo-padding: #{$my-gutter * 0.5} #{$my-gutter * 0.5} #{$my-gutter * 0.25};

      display: inline-block;
      padding: var(--navigation-logo-padding);
      background-color: $white;
      position: relative;
      transform: translateY(0);
      transition: transform 0.25s;

      &:focus-visible {
        outline: 2px solid var(--navigation-links-color);
        outline-offset: -2px;
      }

      // for cardiomed set higher padding because logo is too small
      .root-1035 & {
        --navigation-logo-padding: #{$my-gutter * 0.75} #{$my-gutter * 0.5} #{$my-gutter * 0.75};
      }

      @include media-breakpoint-up(md) {
        --navigation-logo-padding: #{$my-gutter * 0.75} #{$my-gutter * 0.5} #{$my-gutter * 0.5};

        .root-1035 & {
          --navigation-logo-padding: #{$my-gutter} #{$my-gutter * 0.5} #{$my-gutter * 0.75};
        }
      }

      @include media-breakpoint-up(xl) {
        --navigation-logo-padding: #{$my-gutter * 0.75} #{$my-gutter * 0.75} #{$my-gutter * 0.5};

        .root-1035 & {
          --navigation-logo-padding: #{$my-gutter} #{$my-gutter * 0.75} #{$my-gutter * 0.75};
        }
      }

      @include media-breakpoint-up(uw) {
        --navigation-logo-padding: #{$my-gutter * 0.75} #{$my-gutter} #{$my-gutter * 0.5};

        .root-1035 & {
          --navigation-logo-padding: #{$my-gutter * 1.25} #{$my-gutter} #{$my-gutter};
        }
      }

      img {
        max-width: var(--main-navigation-logo-width);
      }
    }
  }

  &-mobile {
    --navigation-mobile-position: fixed;
    --navigation-mobile-width: 50%;

    grid-row: 1;
    grid-column: 2;
    background-color: $white;
    position: var(--navigation-mobile-position);
    width: var(--navigation-mobile-width);
    right: 0;

    @include media-breakpoint-up(xl) {
      --navigation-mobile-position: unset;
      --navigation-mobile-width: unset;
    }

    &__nav {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &-meta {
    --navigation-meta-transform: translateX(100%);
    --navigation-meta-visibility: hidden;
    --navigation-meta-grid-row: 3;
    --navigation-meta-grid-column: 1 / 3;
    --navigation-meta-padding: 0;

    transform: var(--navigation-meta-transform);
    visibility: var(--navigation-meta-visibility);
    grid-row: var(--navigation-meta-grid-row);
    grid-column: var(--navigation-meta-grid-column);
    background-color: var(--navigation-meta-background-color);
    padding: var(--navigation-meta-padding);
    transition: all 0.25s linear;
    display: none;

    @include media-breakpoint-up(md) {
      --navigation-meta-grid-column: 2 / 3;
    }

    @include media-breakpoint-up(xl) {
      --navigation-meta-background-color: #{$extremlightgrey};
      --navigation-meta-grid-row: 1;
      --navigation-meta-grid-column: 1 / 3;
      --navigation-meta-transform: translateX(0);
      --navigation-meta-visibility: visible;
      --navigation-meta-padding: 0 #{$my-gutter} 0 0;
      display: block;
    }

    @include media-breakpoint-up(uw) {
      --navigation-meta-padding: 0;
    }

    .nav-open & {
      --navigation-meta-transform: translateX(0);
      --navigation-meta-visibility: visible;

      display: block;
      min-height: calc(100dvh - var(--header-height) - var(--main-navigation-height));

      @include media-breakpoint-up(md) {
        min-height: calc(100dvh - var(--main-navigation-height) - var(--header-height));
      }
    }

    &__wrapper {
      --navigation-meta-padding: #{$my-gutter * 0.5} #{$my-gutter * 0.5} #{$my-gutter * 0.75};

      max-width: var(--container-max-width);
      margin-inline: auto;
      padding: var(--navigation-meta-padding);

      @include media-breakpoint-up(xl) {
        --navigation-meta-padding: 0;
      }
    }

    &__nav {
      justify-content: flex-end;

      a {
        display: block;
        color: var(--navigation-link-color);
        padding: 12px $my-gutter * 0.25;
        font-size: $my-gutter * 0.5;
        line-height: 1.5;

        &.active {
          text-decoration: none;
          font-weight: 600;
          color: var(--navigation-link-color);
          letter-spacing: -0.3px;
        }

        &:hover {
          text-decoration: none;
          color: inherit;
        }

        &:focus-visible {
          color: $bodycolor;
          outline: 2px solid var(--navigation-links-color);
          outline-offset: 4px;
        }

        @include media-breakpoint-up(xl) {
          padding: $my-gutter * 0.5;

          &:hover,
          &:focus-visible {
            text-decoration: none;
            font-weight: 600;
            color: var(--navigation-link-color);
            letter-spacing: -0.3px;
          }
          &:focus-visible {
            outline: 2px solid $bodycolor;
            outline-offset: -2px;
          }
        }
      }
    }
  }

  &-toggle {
    background-color: unset;
    border: none;
    padding: $my-gutter * 0.5;

    &:focus-visible {
      outline: 2px solid var(--navigation-links-color);
      outline-offset: -2px;
    }
  }

  &-item {
    @include media-breakpoint-up(xl) {
      --navigation-item-border-color: transparent;

      color: var(--navigation-link-color);

      &:hover,
      &:focus,
      &:has(.submenu.open),
      &:has(.active) {
        --navigation-item-border-color: var(--navigation-link-hover-color);

        color: var(--navigation-link-hover-color);
      }
    }

    &.has-submenu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-up(xl) {
        justify-content: unset;
      }
    }

    // submenu 1st level
    > .submenu {
      --submenu-padding: calc((var(--main-navigation-logo-height) - var(--header-height)) + #{$my-gutter}) #{$my-gutter *
        0.75} #{$my-gutter};
      --submenu-left: 100%;
      --submenu-top: 0;
      --submenu-visibility: visible;
      --submenu-height: calc(100dvh - var(--header-height));

      padding: var(--submenu-padding);
      background: var(--highlight-1-color);
      top: var(--submenu-top);
      left: var(--submenu-left);
      height: var(--submenu-height);
      visibility: var(--submenu-visibility);

      position: absolute;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      transition: all 0.25s linear;

      @include media-breakpoint-up(md) {
        --submenu-padding: #{$my-gutter} #{$my-gutter * 0.75};
      }

      @include media-breakpoint-up(xl) {
        --submenu-left: 0;
        --submenu-top: 0%;
        --submenu-height: auto;
        --submenu-visibility: hidden;

        padding: unset;
        transition: none;
        max-height: 75dvh;
      }

      // when submenu is open
      &.open {
        --submenu-top: 0;
        --submenu-left: 0;

        @include media-breakpoint-up(xl) {
          --submenu-top: 100%;
          --submenu-visibility: visible;

          overflow-x: hidden;
          overflow-y: auto;
        }
      }

      // submenu toggle 1st level
      &-toggle {
        --navigation-toggle-padding: 12px 0;

        padding: var(--navigation-toggle-padding);
        border: unset;
        background-color: unset;
        height: 100%;
        color: var(--navigation-link-color);

        &:focus-visible {
          color: $bodycolor;
          outline: 2px solid var(--navigation-links-color);
          outline-offset: 4px;
        }

        @include media-breakpoint-up(xl) {
          --navigation-toggle-padding: #{$my-gutter * 0.75} #{$my-gutter * 0.25} calc(#{$my-gutter * 0.75} - 3px);

          color: currentColor;
          border-bottom: 3px solid var(--navigation-item-border-color);

          // when submenu is open, transform the toggle icon
          &:has(+ .submenu.open) {
            svg {
              transform: translateY(6px);
            }
          }

          svg {
            transition: transform 0.25s ease-in;
            transform: translateY(0px);
          }

          &:hover {
            color: inherit;
            text-decoration: none;
            border-bottom-color: currentColor;

            svg {
              transform: translateY(6px);
            }
          }

          &.active,
          &:focus-visible {
            text-decoration: none;
            border-bottom-color: currentColor;
          }

          &:focus-visible {
            color: var(--navigation-link-hover-color);
            border-bottom: 3px solid;
            outline: 2px solid var(--navigation-link-hover-color);
            outline-offset: -2px;

            svg {
              transform: translateY(6px);
            }
          }
        }

        @include media-breakpoint-up(uw) {
          --navigation-toggle-padding: #{$my-gutter} #{$my-gutter * 0.25} calc(#{$my-gutter} - 3px);
        }
      }
    }

    // submenu 2nd level
    .submenu {
      &:not(.open) {
        @include media-breakpoint-up(xl) {
          .submenu.open {
            --submenu-visibility: hidden;
          }
        }
      }

      .submenu {
        --submenu-left: 110%;
        --submenu-top: 0;
        --submenu-height: calc(100% + var(--meta-navigation-height));
        --submenu-width: 100%;
        --submenu-bg-color: var(--highlight-1-color);
        --submenu-visibility: visible;

        padding: 0;
        background-color: var(--submenu-bg-color);
        top: var(--submenu-top);
        left: var(--submenu-left);
        height: var(--submenu-height);
        width: var(--submenu-width);
        visibility: var(--submenu-visibility);

        position: absolute;
        max-height: 100%;
        overflow: scroll;
        transition: all 0.25s linear;

        &.submenu-last-level {
          overflow: visible;
        }

        @include media-breakpoint-up(xl) {
          --submenu-height: auto;
          --submenu-width: 50%;

          min-height: 100%;
          max-height: unset;
          overflow: visible;
          transition: none;
          --submenu-visibility: hidden;
        }

        &.open {
          --submenu-left: 0;

          @include media-breakpoint-up(xl) {
            --submenu-bg-color: var(--highlight-2-color);
            --submenu-left: 50%;
            --submenu-visibility: visible;
          }
        }

        .submenu-menu {
          width: 100%;

          @include media-breakpoint-up(xl) {
            --submenu-menu-padding: #{$my-gutter * 1.5};

            padding: var(--submenu-menu-padding);
          }

          @include media-breakpoint-up(uw) {
            --submenu-menu-padding: #{$my-gutter * 1.75};
          }
        }

        // submenu li item
        &-item {
          --submenu-item-color: #{$bodycolor};

          color: var(--submenu-item-color);

          @include media-breakpoint-up(xl) {
            --submenu-item-color: #{$bodycolor-light};

            &:hover,
            &:has(.active),
            &:has(.open) {
              --submenu-item-color: #{$bodycolor};
            }

            &:has(.open),
            &:hover {
              .submenu-toggle {
                --arrow-width: 64px;
              }
            }
          }

          &.has-submenu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $my-gutter * 0.5;

            &:hover {
              color: $bodycolor;
            }
          }

          // submenu toggle in 2nd level
          .submenu-toggle {
            --navigation-toggle-padding: 12px 0;
            --arrow-width: 27px;
            --arrow-line-height: 2px;

            padding: var(--navigation-toggle-padding);
            background-color: transparent;
            color: currentColor;
            border: none;
            text-align: left;

            &:focus-visible {
              color: $bodycolor;
              outline: 2px solid var(--navigation-links-color);
              outline-offset: 4px;
            }

            @include media-breakpoint-up(xl) {
              --navigation-toggle-padding: 0;

              max-width: 80px;
              width: 100%;

              &:focus-visible {
                --arrow-width: 64px;
              }
            }
          }
        }

        .submenu-item:has(.active) {
          --submenu-item-color: #{$bodycolor};
        }

        .submenu-link {
          @include media-breakpoint-up(xl) {
            font-weight: 400;
          }
        }

        &-link {
          font-size: 16px;
          font-weight: 600;
          color: currentColor;
          padding: 12px 0;
          display: block;

          &.active {
            text-decoration: underline;
            text-underline-offset: 8px;
            text-decoration-thickness: 2px;
          }

          &:hover,
          &:focus-visible {
            text-decoration: none;
          }

          &:focus-visible {
            color: $bodycolor;
            outline: 2px solid var(--navigation-links-color);
            outline-offset: 4px;
          }

          @include media-breakpoint-up(xl) {
            padding: 0;

            &.active {
              text-decoration: none;
            }
          }
        }

        &-menu {
          --submenu-menu-padding: #{$my-gutter * 1.5} #{$my-gutter * 1.5} #{$my-gutter * 1.5} 0;

          list-style: unset;
          margin: 0;
          padding-left: 0;

          @include media-breakpoint-up(xl) {
            padding: var(--submenu-menu-padding);
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: $my-gutter;
            min-height: var(--submenu-min-height);
          }

          @include media-breakpoint-up(uw) {
            --submenu-menu-padding: #{$my-gutter * 1.75} #{$my-gutter * 1.75} #{$my-gutter * 1.75} 0;
          }
        }

        &-close {
          color: var(--navigation-link-color);
          border: none;
          background-color: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 0;
          font-weight: 600;

          &:focus-visible {
            color: $bodycolor;
            outline: 2px solid var(--navigation-links-color);
            outline-offset: 4px;
          }
        }

        &-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          text-transform: uppercase;
        }
      }
    }
  }

  &-link {
    --navigation-link-padding: 12px 0;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    padding: var(--navigation-link-padding);
    display: inline-block;
    text-transform: uppercase;
    border-bottom: 3px solid var(--navigation-item-border-color);
    color: var(--navigation-link-color);

    &.active,
    &:hover {
      color: var(--navigation-link-color);
      text-decoration: none;
    }

    &.active {
      text-decoration: underline;
      text-underline-offset: 8px;
      text-decoration-thickness: 2px;
    }

    &:focus-visible {
      color: $bodycolor;
      outline: 2px solid var(--navigation-links-color);
      outline-offset: 4px;
    }

    @include media-breakpoint-up(xl) {
      --navigation-link-padding: #{$my-gutter * 0.75} #{$my-gutter * 0.25} calc(#{$my-gutter * 0.75} - 3px);

      font-size: 16px;
      color: currentColor;
      height: 100%;
      align-content: center;

      &.active,
      &:hover {
        color: inherit;
      }

      &.active {
        text-decoration: none;
      }

      &:focus-visible {
        color: var(--navigation-link-hover-color);
        text-decoration: none;
      }

      &:focus-visible {
        outline: 2px solid var(--navigation-link-hover-color);
        outline-offset: -2px;
        border-bottom: 3px solid;
      }
    }

    @include media-breakpoint-up(uw) {
      --navigation-link-padding: #{$my-gutter} #{$my-gutter * 0.25} calc(#{$my-gutter} - 3px);
    }
  }
}

.main-wrapper {
  display: contents;
  max-width: var(--container-max-width);
  margin-inline: auto;
  width: 100%;

  > div {
    display: contents;
    position: unset;
  }

  @include media-breakpoint-up(xl) {
    display: block;
    grid-column: 1 / 3;

    > div {
      display: flex;
      justify-content: space-between;
    }
  }
}
