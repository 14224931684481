.fc {
  .fc-event {
    border-color: $primary;
    color: #ffffff !important;
  }
  .fc-event,
  .fc-event-dot {
    background-color: $primary;
  }
  .fc-today-button {
    text-transform: lowercase;
  }
  .fc-header-toolbar {
    text-transform: lowercase;
    h2 {
      font-weight: 600;
      color: $primary;
    }
    .fc-state-active {
      font-weight: 600;
    }
    .fc-button {
      background: none;
      border: none;
      box-shadow: none;
      text-transform: lowercase;
      color: $dark-grey;
    }
  }
}

.filter-list.filter-list,
.selected-filters.selected-filters {
  margin-top: 30px;
  .filter-element {
    background-color: var(--maincolor);
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 7.5px 15px;
    color: #ffffff;
    font-size: 15px;
    &:hover {
      cursor: pointer;
    }

    label {
      cursor: pointer;
    }

    input {
      appearance: none;
    }
  }
}

.fc-selected-filters {
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-transition:
    visibility 500ms,
    opacity 499ms,
    max-height 499ms;
  -moz-transition:
    visibility 500ms,
    opacity 499ms,
    max-height 499ms;
  -ms-transition:
    visibility 500ms,
    opacity 499ms,
    max-height 499ms;
  -o-transition:
    visibility 500ms,
    opacity 499ms,
    max-height 499ms;
  transition:
    visibility 500ms,
    opacity 499ms,
    max-height 499ms;

  &.active {
    visibility: visible;
    opacity: 1;
    max-height: 1000px;
    -webkit-transition:
      visibility 1000ms,
      opacity 999ms,
      max-height 999ms;
    -moz-transition:
      visibility 1000ms,
      opacity 999ms,
      max-height 999ms;
    -ms-transition:
      visibility 1000ms,
      opacity 999ms,
      max-height 999ms;
    -o-transition:
      visibility 1000ms,
      opacity 999ms,
      max-height 999ms;
    transition:
      visibility 1000ms,
      opacity 999ms,
      max-height 999ms;
  }
  .reset-filters {
    text-align: right;
    color: $primary;
    font-size: 25px;
    font-weight: 300;
    &:after {
      content: 'x';
      margin-left: 15px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.fc-toolbar {
  margin-bottom: 7.5px;
  flex-wrap: wrap;

  .fc-prev-button,
  .fc-next-button {
    .fc-icon {
      &::after {
        font-size: 150%;
        display: block;
        margin-top: -25%;
      }
    }
  }
}

.fc-view-container {
  margin-top: 7.5px;
}

.selected-category {
  background: $primary;
  border-radius: 5px;
  padding: 6.5px;
  display: inline-block;
  color: #ffffff;
  margin-right: 15px;
  margin-bottom: 15px;
  font-size: 15px;
  &:after {
    content: 'X';
    border: 1px solid #fff;
    border-radius: 50%;
    margin-left: 15px;
    width: 24px;
    height: 24px;
    display: inline-block;
    text-align: center;
  }
  &:hover {
    cursor: pointer;
  }
}

.event-list {
  max-height: 1100px;
  overflow: hidden;
  -webkit-transition: max-height 500ms;
  -moz-transition: max-height 500ms;
  -ms-transition: max-height 500ms;
  -o-transition: max-height 500ms;
  transition: max-height 500ms;
}
.event-list,
.event-list-filtered {
  margin-top: 120px;
}
.show-more {
  i {
    font-size: 50px;
  }
  &:hover {
    cursor: pointer;
  }
}
.tx-md-fullcalendar .filter-element {
  padding: 0px !important;
}
.tx-md-fullcalendar .filter-element > input {
  opacity: 0;
}
.tx-md-fullcalendar .filter-element label {
  width: 100%;
  height: 100%;
  padding: 7.5px 15px;
}
.tx-md-fullcalendar .selected-filters {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
}
.tx-md-fullcalendar .selected-filters .filter-element {
  margin-right: 5px;
  margin-bottom: 5px;
}

.tx-md-fullcalendar #selected-filters .filter-element label:after {
  content: 'X';
  border: 1px solid #fff;
  border-radius: 50%;
  margin-left: 15px;
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
}
.calendarize .event-list {
  max-height: unset;
}
.calendarize .event-list > .row > .col-12.col-md-6.col-lg-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.calendarize .event .event-img {
  margin-bottom: 15px;
  overflow: hidden;
}
.calendarize .event .event-date {
  margin-bottom: 5px;
  font-weight: 600;
}
.calendarize .event .header a {
  margin-bottom: 15px;
  color: #636362;
}
.calendarize .event .teaser-text {
  color: #000000;
  margin-bottom: 15px;
}
.calendarize .show-more > ul {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-top: 15px;
}
/* TODO: INTO GLOBAL CSS */
.calendarize .show-more > ul > li {
  padding: 7.5px 15px;
}
/* TODO: INTO GLOBAL CSS */
.calendarize .show-more > ul > li.active {
  background-color: #b2b2b2;
}
.calendarize .show-more > ul > li > a {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
}

.fc-scroller {
  &.fc-day-grid-container {
    height: auto !important;
    overflow: auto !important;
  }
}

/** refactoring **/
.selected-filters {
  flex: 1 0 100%;
  margin: $grid-gutter-width * 0.5 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $grid-gutter-width * 0.5;

  .reset.reset {
    margin: 0;
    align-self: flex-end;

    button {
      border: none;
      background: transparent;
      color: var(--maincolor);
      font-size: 25px;
      font-weight: 300;
      padding: 0;
      height: auto;

      &:after {
        content: 'x';
        margin-left: $grid-gutter-width * 0.5;
      }
    }
  }

  .inputs {
    order: 1;
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    gap: $grid-gutter-width * 0.25;

    .filter-element {
      margin: 0;

      label {
        display: flex;
        gap: $grid-gutter-width * 0.5;

        &:after {
          content: 'X';
          border: 1px solid #fff;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          display: inline-block;
          text-align: center;
          line-height: 1.35;
          letter-spacing: 1px;
        }
      }
    }
  }
}
