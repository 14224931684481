/**
  Wrapper
*/
.image-slider {
  position: relative;
  max-width: 730px;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 40px;

  &__container {
    position: relative;
  }

  &__figure {
    margin-bottom: 0;
  }

  .splide {
    &__list {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: nowrap;
    }

    &__pagination {
      position: static;
      top: unset;
      left: unset;
      bottom: unset;
      transform: none;
      flex-wrap: nowrap;
      width: 100%;
      min-height: 46px;
    }
  }

  .previous-button {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: -15px;
  }

  .next-button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -15px;
  }

  .previous-button,
  .next-button {
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    line-height: 1;
  }
}

.lightbox {
  display: block;
}

/**
  Controls - pause/resume, previous, and next buttons
*/

/* Resets for all buttons */
.is-control {
  z-index: 5;
  background: none;
  color: rgba(0, 0, 0, 0.7);
  border: 0;
  cursor: pointer;
  transition: all 0.2s linear;

  &__svg-wrapper {
    border: 1px solid transparent;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    height: 15px;
    width: 15px;
  }

  &:focus {
    outline: none;
  }
}

/**
  Single slide
*/
.splide__slide {
  position: relative;
  height: auto;
  visibility: hidden;
  display: flex;
  flex-direction: column;
}

.splide__slide.is-visible {
  visibility: visible;
}

/**
  Restyle the slide dots generated by Slick Slider
*/
.splide__pagination {
  bottom: -35px;
}

.splide__pagination {
  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li + li {
    margin-left: 10px;
  }
}

/* Create a circle to serve as the slide dot */
.splide__pagination li button {
  width: 15px;
  height: 15px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 15px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #212529;
}

/* Give the focused slide dot a nice visible focus indicator */
.splide__pagination li button:focus,
.is-control:focus .is-control__svg-wrapper {
  box-shadow:
    0 0 0 1px $light,
    0 0 0 4px $slider-focus;
  outline: none;
}

/* Leverage aria-current for styling to ensure we're using the attribute correctly */
.splide__pagination li button[aria-current='true'] {
  background-color: $dark;
  transform: none;
  opacity: 1;
}
