/**
  use this mixin, if you need in your element for specific selectors the new defined main colors

  example:
  .foo {
    @include theme-color-change;
  }

 */
@mixin theme-color-change {
  // eli linz wien
  .root-1 & {
    --maincolor: var(--maincolor-eli);
    --maincolor-hover: var(--maincolor-eli-hover);
    --highlight-1-color: var(--highlight-1-eli);
    --highlight-2-color: var(--highlight-2-eli);

    --navigation-links-color: var(--maincolor);
  }

  // wohnen
  .root-346 & {
    --btn-color: #{$bodycolor};
    --btn-color-hover: var(--btn-color);

    --maincolor: var(--maincolor-wohnen);
    --maincolor-hover: var(--maincolor-wohnen-hover);
    --maincolor-rgb: #{red($turquoise-500) + ', ' + green($turquoise-500) + ', ' + blue($turquoise-500)};
    --highlight-1-color: var(--highlight-1-wohnen);
    --highlight-2-color: var(--highlight-2-wohnen);

    --quicklinks-color: #{$bodycolor};
    --sm-icon-color: var(--quicklinks-color);
    --navigation-links-color: #{$bodycolor};
  }

  // forte & forte e-learning
  .root-373 &,
  .root-1589 & {
    --btn-color: #{$bodycolor};
    --btn-color-hover: var(--btn-color);

    --maincolor: var(--maincolor-forte);
    --maincolor-hover: var(--maincolor-forte-hover);
    --maincolor-rgb: #{red($yellow-500) + ', ' + green($yellow-500) + ', ' + blue($yellow-500)};
    --highlight-1-color: var(--highlight-1-forte);
    --highlight-2-color: var(--highlight-2-forte);

    --quicklinks-color: #{$bodycolor};
    --sm-icon-color: var(--quicklinks-color);
    --navigation-links-color: #{$bodycolor};
  }

  // elisana
  .root-265 & {
    --maincolor: var(--maincolor-elisana);
    --maincolor-hover: var(--maincolor-elisana-hover);
    --maincolor-rgb: #{red($green-500) + ', ' + green($green-500) + ', ' + blue($green-500)};
    --highlight-1-color: var(--highlight-1-elisana);
    --highlight-2-color: var(--highlight-2-elisana);

    --quicklinks-color: #{$bodycolor};
    --sm-icon-color: var(--quicklinks-color);
    --navigation-links-color: var(--maincolor);
  }

  // medikcal
  .root-396 & {
    --btn-color: #{$bodycolor};
    --btn-color-hover: var(--btn-color);

    --maincolor: var(--maincolor-medical);
    --maincolor-hover: var(--maincolor-medical-hover);
    --maincolor-rgb: #{red($yellow-100) + ', ' + green($yellow-100) + ', ' + blue($yellow-100)};
    --highlight-1-color: var(--highlight-1-medical);
    --highlight-2-color: var(--highlight-2-medical);

    --quicklinks-color: #{$bodycolor};
    --sm-icon-color: var(--quicklinks-color);
    --navigation-links-color: #{$bodycolor};
  }
  // health
  .root-581 & {
    --btn-color: #{$bodycolor};
    --btn-color-hover: var(--btn-color);

    --maincolor: var(--maincolor-health);
    --maincolor-hover: var(--maincolor-health-hover);
    --maincolor-rgb: #{red($blue-300) + ', ' + green($blue-300) + ', ' + blue($blue-300)};
    --highlight-1-color: var(--highlight-1-health);
    --highlight-2-color: var(--highlight-2-health);

    --quicklinks-color: #{$bodycolor};
    --sm-icon-color: var(--quicklinks-color);
    --navigation-links-color: #{$bodycolor};
  }

  // cardiomed
  .root-1035 & {
    --maincolor: var(--maincolor-cardiomed);
    --maincolor-rgb2: #{red($blue-100) + ', ' + green($blue-100) + ', ' + blue($blue-100)};
    --maincolor-hover: var(--maincolor-cardiomed-hover);
    --highlight-1-color: var(--highlight-1-cardiomed);
    --highlight-2-color: var(--highlight-2-cardiomed);

    --navigation-links-color: var(--maincolor);
  }

  // st. elisabeth pflege
  .root-1083 & {
    --btn-color: #{$bodycolor};
    --btn-color-hover: var(--btn-color);

    --maincolor: var(--maincolor-eli-pflege);
    --maincolor-hover: var(--maincolor-eli-pflege-hover);
    --maincolor-rgb: #{red($orange-500) + ', ' + green($orange-500) + ', ' + blue($orange-500)};
    --maincolor-rgb2: #{red($yellow-100) + ', ' + green($yellow-100) + ', ' + blue($yellow-100)};
    --highlight-1-color: var(--highlight-1-eli-pflege);
    --highlight-2-color: var(--highlight-2-eli-pflege);

    --quicklinks-color: #{$bodycolor};
    --sm-icon-color: var(--quicklinks-color);
    --navigation-links-color: #{$bodycolor};
  }

  // gesundheitsnetzwerk
  .root-1410 & {
    --maincolor: var(--maincolor-gesundheitsnetzwerk);
    --maincolor-hover: var(--maincolor-gesundheitsnetzwerk-hover);
    --maincolor-rgb: #{red($blue-500) + ', ' + green($blue-500) + ', ' + blue($blue-500)};
    --maincolor-rgb2: #{red($yellow-100) + ', ' + green($yellow-100) + ', ' + blue($yellow-100)};
    --highlight-1-color: var(--highlight-1-gesundheitsnetzwerk);
    --highlight-2-color: var(--highlight-2-gesundheitsnetzwerk);
    --navigation-links-color: var(--maincolor);
  }

  // eli-at
  .root-1532 & {
    --maincolor-hover: var(--maincolor-eliat-hover);
    --highlight-1-color: var(--highlight-1-eliat);
    --highlight-2-color: var(--highlight-2-eliat);

    --navigation-links-color: var(--maincolor);
  }
}
