.hamburger {
  display: block;
  height: $my-gutter * 0.75;
  aspect-ratio: 1 / 1;
  position: relative;
  color: $bodycolor;

  .bread,
  .patty {
    --offset: -1px;
    --rotation: 0;
    --width: 100%;

    display: block;
    position: absolute;
    width: var(--width);
    height: 2px;
    background: currentColor;
    transform-origin: center center;
    top: 50%;
    left: 50%;
    transform: translateY(var(--offset)) translateX(-50%) rotateZ(var(--rotation));

    transition: all 0.3s ease;
  }

  .bread {
    &--top {
      margin-top: -30%;
    }

    &--bottom {
      margin-top: 30%;
    }
  }

  [aria-expanded='true'] & {
    .bread {
      --offset: 0;
      --width: 106%;

      margin-top: 0;

      &--top {
        --rotation: 45deg;
      }

      &--bottom {
        --rotation: -45deg;
      }
    }

    .patty {
      opacity: 0;
    }
  }
}
