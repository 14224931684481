.page {
  &-title {
    font-size: calc(22px + 2vw);
    line-height: 1.3;
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
      font-size: 48px;
    }
  }
}
