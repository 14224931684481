$my-gutter: 32px;

$spacers: (
  0: 0,
  og: $my-gutter * 0.125,
  qg: $my-gutter * 0.25,
  sg: $my-gutter * 0.375,
  hg: $my-gutter * 0.5,
  qp: $my-gutter * 0.75,
  fg: $my-gutter,
  1qg: $my-gutter * 1.25,
  1h: $my-gutter * 1.5,
  dg: $my-gutter * 2,
  2qg: $my-gutter * 2.25,
  2h: $my-gutter * 2.5,
  3h: $my-gutter * 3.5,
  qtg: $my-gutter * 4,
);

$attributes: (
  m: margin,
  mb: margin-bottom,
  mt: margin-top,
  ml: margin-left,
  mr: margin-right,
  my: (
    margin-top,
    margin-bottom,
  ),
  mx: (
    margin-left,
    margin-right,
  ),
  p: padding,
  pb: padding-bottom,
  pt: padding-top,
  pl: padding-left,
  pr: padding-right,
  py: (
    padding-top,
    padding-bottom,
  ),
  px: (
    padding-left,
    padding-right,
  ),
  gap: gap,
  row-gap: row-gap,
  column-gap: column-gap,
);

$infix: '-';

@each $bp, $size in $grid-breakpoints {
  @if $bp != 'xs' {
    $infix: '-#{$bp}-';
  }

  @include media-breakpoint-up($bp) {
    @each $prefix, $attr in $attributes {
      @each $spacerName, $spacerValue in $spacers {
        .#{$prefix}#{$infix}#{$spacerName} {
          @if (type-of($attr) == 'list') {
            @each $attribute in $attr {
              #{$attribute}: #{$spacerValue} !important;
            }
          } @else {
            #{$attr}: #{$spacerValue} !important;
          }
        }
      }
    }
  }
}

.comma-separated-list {
  li {
    word-break: break-word;
    hyphens: auto;

    &:after {
      content: ', ';
    }

    &:last-of-type:after {
      content: '';
    }
  }
}
