.skip-nav {
  @include theme-color-change;

  --btn-color: #{$light};
  --btn-color-hover: #{$light};
  --btn-bg-color: var(--maincolor);
  --btn-bg-color-hover: var(--maincolor-hover);

  position: absolute;
  top: 3px;
  left: 3px;

  &__link.btn {
    display: block;
    position: absolute;
    top: -200px;
    z-index: 1000;
    padding: $my-gutter * 0.5;
    font-size: var(--btn-font-size);
    font-weight: 600;
    color: var(--btn-color);
    background: var(--btn-bg-color);
    border-color: var(--btn-bg-color);

    &:focus-visible {
      top: 0;
      z-index: 1001;
      color: var(--btn-color);
      background: var(--btn-bg-color);
      border-color: var(--btn-bg-color);
      box-shadow: none;
      outline: 2px solid var(--btn-bg-color);
      outline-offset: 2px;
    }
  }
}
