@import '../../../../../../vendor/supseven/supi/Build/Src/Scss/Supi';

:root {
  --supi-color-primary: #212529;
}

.tx-supi__simpleMaps {
  &-preview {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  &-text {
    grid-column: 1/1;
    grid-row: 1/1;
    z-index: 1;
    place-content: center;
    display: grid;
  }

  &-wrapper {
    iframe {
      aspect-ratio: 16/9;
      width: 100%;
    }
  }

  figure {
    grid-column: 1/1;
    grid-row: 1/1;
    margin: 0;

    img {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }

  &-overlay {
    background: #fff;
  }
}
