@mixin custom-select($bg-color) {
  appearance: none;
  background-color: $bg-color !important;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23212529' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat, repeat !important;
  background-position:
    right 1.1875em top 50%,
    0 0 !important;
  background-size:
    0.875em auto,
    100% !important;
}
